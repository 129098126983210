/* eslint-disable @typescript-eslint/no-explicit-any */
'use client'

import { useEffect } from 'react'
import { domAnimation, LazyMotion } from 'framer-motion'

import { NormalContainer } from '../components/layout/container/Normal'

export default function Error({ error }: any) {
  useEffect(() => {
    console.log(error)
    // captureException(error)
  }, [error])
  return (
    <html>
      <head>
        <title>出错啦</title>
      </head>
      <body>
        <NormalContainer>
          <p>{error?.message || '未知错误'}</p>
          <LazyMotion features={domAnimation}>
            <button onClick={location.reload}>重试</button>
          </LazyMotion>
        </NormalContainer>
      </body>
    </html>
  )
}
